import React, { useContext, useEffect, useState, useRef } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AnswersLogic from "../Logic/AnswersLogic";
import AnswerType from "../Answer/AnswerType";
import ChatbotHeader from "../ChatbotHeader/";
import DefaultQuestion from "../Answer/DefaultQuestion";
import DisclaimerInitial from "../Answer/DisclaimerInitial/";
import JsonDictionaryService from "../../Services/JsonDictionaryService";
import NavigationHeader from "../NavigationHeader";
import { useInView, InView } from "react-intersection-observer";

const ChatbotBody = (props) => {
  const { ref } = useInView({
    threshold: 0.8,
  });
  const { chatbot, createDictionary, setChatbot } = useContext(Context);
  const history = useHistory();
  const [chatbotTemp, setChatbotTemp] = useState({ ...chatbot });
  const [forceUpdate, setForceUpdade] = useState(0);
  const [logic, setLogic] = useState(false);
  const [initialIdQuestion, setInitialIdQuestion] = useState(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const autoScrollTimeout = useRef(null);
  let lastQuestion = chatbotTemp.questions.length;

  const addQuestion = async (questionNumber = null) => {
    let existOpenQuestions = chatbotTemp.questions.some(
      (question) => question.edit
    );

    let errorExists = false;
    if (existOpenQuestions) {
      errorExists = validQuestions();
    }

    if (!errorExists) {
      let newQuestionNumber = 0;
      for (let i = 0; i < chatbotTemp.questions.length; i++) {
        if (chatbotTemp.questions[i].number > newQuestionNumber) {
          newQuestionNumber = chatbotTemp.questions[i].number;
        }
      }
      newQuestionNumber++;

      if (props.usedNumbers.indexOf(newQuestionNumber) > -1) {
        newQuestionNumber = Math.max(...props.usedNumbers) + 1;
        props.setUsedNumbers([...props.usedNumbers, newQuestionNumber]);
      } else {
        props.setUsedNumbers([...props.usedNumbers, newQuestionNumber]);
      }

      if (questionNumber === "welcome") {
        questionNumber = -1;
      }

      if (questionNumber === null) {
        let arrayTemp = [...chatbotTemp.questions];

        for (let i = 0; i < arrayTemp.length; i++) {
          arrayTemp[i].edit = 0;
        }

        setChatbotTemp({
          ...chatbotTemp,
          questions: [
            ...arrayTemp,
            {
              message: "",
              number: newQuestionNumber,
              options: [],
              type: "",
              edit: 1,
              error: false,
            },
          ],
        });
      } else {
        let arrayTemp = [...chatbotTemp.questions];
        let newArrayOptions = [
          ...arrayTemp.slice(0, questionNumber + 1),
          {
            message: "",
            number: newQuestionNumber,
            options: [],
            type: "",
            edit: 1,
            error: false,
          },
          ...arrayTemp.slice(questionNumber + 1, arrayTemp.length),
        ];
        setChatbotTemp({
          ...chatbotTemp,
          questions: [...newArrayOptions],
        });
      }
    }
  };

  const handleAddOption = (questionNumber) => {
    let arrayTemp = [...chatbotTemp.questions];
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: [
        ...arrayTemp[questionNumber].options,
        { color: "#606c88", next: 0, textColor: "#ffffff", value: "" },
      ],
      error: false,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const handleScroll = (questionNumber) => {
    if (questionNumber) {
      return window.scrollTo({
        top:
          document.getElementById(`Pergunta${questionNumber}`).offsetTop -
          65 -
          65 -
          63,
        behavior: "smooth",
      });
    } else {
      return null;
    }
  };

  const handleCancel = (questionNumber) => {
    handleScroll(questionNumber);
    let questionNumberWillCancel = chatbotTemp.questions[questionNumber].number;
    let originalQuestion = null;

    for (let i = 0; i < chatbot.questions.length; i++) {
      if (chatbot.questions[i].number === questionNumberWillCancel) {
        originalQuestion = chatbot.questions[i];
      }
    }

    let arrayTemp = chatbotTemp.questions;
    if (originalQuestion !== null) {
      arrayTemp[questionNumber] = {
        ...originalQuestion,
        edit: 0,
        error: false,
      };
      setChatbotTemp({
        ...chatbot,
        questions: arrayTemp,
      });
    } else {
      arrayTemp.splice(questionNumber, 1);
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
    }
  };

  const handleColor = (questionNumber, optionNumber, backColor, textColor) => {
    let arrayTemp = [...chatbotTemp.questions];
    let arrayWithNewColor = [...arrayTemp[questionNumber].options];
    arrayWithNewColor[optionNumber] = {
      ...arrayWithNewColor[optionNumber],
      color: backColor,
      textColor: textColor,
    };
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: arrayWithNewColor,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const validQuestions = () => {
    let arrayTemp = [...chatbotTemp.questions];
    let disclaimer_initialTemp = { ...chatbotTemp.disclaimer_initial };
    let errorExists = false;
    let whereIsError = "";

    if (disclaimer_initialTemp.value === "") {
      disclaimer_initialTemp = {
        ...disclaimer_initialTemp,
        error: true,
      };
      whereIsError = "DisclaimerInicial";
      errorExists = true;
    }

    if (arrayTemp.length > 0) {
      for (let i = 0; i < arrayTemp.length; i++) {
        if (arrayTemp[i].edit === 1) {
          if (arrayTemp[i].message === "") {
            arrayTemp[i] = {
              ...arrayTemp[i],
              error: true,
            };
            errorExists = true;
            whereIsError = `Pergunta${i}`;
          } else if (arrayTemp[i].type === "") {
            arrayTemp[i] = {
              ...arrayTemp[i],
              error: true,
            };
            errorExists = true;
            whereIsError = `Pergunta${i}`;
          } else {
            switch (arrayTemp[i].type) {
              case "closeAnswer":
              case "emojiScale":
              case "multipleChoice":
              case "starThreeScale":
              case "starFiveScale":
              case "menuInteractive":
              case "likeDislike":
              case "numericalScale":
                for (let j = 0; j < arrayTemp[i].options.length; j++) {
                  if (arrayTemp[i].options[j].value === "") {
                    arrayTemp[i] = {
                      ...arrayTemp[i],
                      error: true,
                    };
                    errorExists = true;
                    whereIsError = `Pergunta${i}`;
                  }
                }
                break;

              case "insertImage":
              case "insertVideo":
                if (arrayTemp[i].options === "") {
                  arrayTemp[i] = {
                    ...arrayTemp[i],
                    error: true,
                  };
                  errorExists = true;
                  whereIsError = `Pergunta${i}`;
                }
                break;
              default:
                break;
            }
          }
        }
      }
    }

    if (errorExists) {
      window.scrollTo({
        top: document.getElementById(whereIsError).offsetTop - 65 - 65 - 63,
        behavior: "smooth",
      });
    }

    setChatbotTemp({
      ...chatbotTemp,
      disclaimer_initial: disclaimer_initialTemp,
      questions: arrayTemp,
    });

    return errorExists;
  };

  const handleDuplicateQuestion = async (questionNumber) => {
    let newQuestionNumber = 0;
    for (let i = 0; i < chatbotTemp.questions.length; i++) {
      if (chatbotTemp.questions[i].number > newQuestionNumber) {
        newQuestionNumber = chatbotTemp.questions[i].number;
      }
    }

    let newQuestion = [
      Object.assign({}, chatbotTemp.questions[questionNumber]),
    ];

    let firstSlice = Array.from(chatbotTemp.questions.slice(0, questionNumber));
    let secondSlice = Array.from(
      chatbotTemp.questions.slice(
        questionNumber,
        chatbotTemp.questions[questionNumber].length
      )
    );

    let newArrayOptions = [].concat(firstSlice, newQuestion, secondSlice);
    newArrayOptions[questionNumber + 1].number = newQuestionNumber + 1;
    setChatbotTemp({
      ...chatbotTemp,
      questions: newArrayOptions,
    });

    let projectJson = JSON.stringify({
      ...chatbotTemp,
      questions: [...newArrayOptions],
    });
    await createDictionary(
      {
        ...chatbotTemp,
        questions: [...newArrayOptions],
      },
      props.projectId
    );
    await JsonDictionaryService.jsonDictionarySaveFirebase(
      props.projectId,
      projectJson
    );
  };

  const handleEdit = (questionNumber) => {
    let errorExists = validQuestions();
    if (!errorExists) {
      let arrayTemp = [...chatbotTemp.questions];
      for (let i = 0; i < arrayTemp.length; i++) {
        let valueToEdit = 0;
        if (i === questionNumber) {
          valueToEdit = 1;
        }
        arrayTemp[i] = {
          ...arrayTemp[i],
          edit: valueToEdit,
        };
      }
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e !== null) {
      if (e.target !== null) {
        e.target.style.height = `${e.target.scrollHeight}px`;
      }
    }
  };

  const handleMessage = (questionNumber, message) => {
    let arrayTemp = [...chatbotTemp.questions];
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      message: message,
      error: false,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const handleNextOptions = (questionNumber, optionNumber, value) => {
    let arrayTemp = JSON.parse(JSON.stringify(chatbotTemp));

    if (value === "Próxima Pergunta") {
      arrayTemp.questions[questionNumber].options[optionNumber].next = 0;
    } else {
      arrayTemp.questions[questionNumber].options[optionNumber].next =
        Number(value);
    }

    setChatbotTemp(arrayTemp);
  };

  const handleNextMultipleOptions = (questionNumber, value) => {
    let arrayTemp = JSON.parse(JSON.stringify(chatbotTemp));

    if (value === "Próxima Pergunta") {
      arrayTemp.questions[questionNumber].options.map((option, index) => {
        return (option.next = 0);
      });
    } else {
      arrayTemp.questions[questionNumber].options.map((option, index) => {
        return (option.next = Number(value));
      });
    }

    setChatbotTemp(arrayTemp);
  };

  const handleOptionValue = (questionNumber, optionNumber, value) => {
    let arrayTemp = [...chatbotTemp.questions];
    let arrayWithNewColor = [...arrayTemp[questionNumber].options];
    arrayWithNewColor[optionNumber] = {
      ...arrayWithNewColor[optionNumber],
      value: value.replace(/\n/g, " "),
    };
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: arrayWithNewColor,
      error: false,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const handleQuestion = (question, position, ref) => {
    const arrayTypes = [
      "closeAnswer",
      "emojiScale",
      "insertImage",
      "insertVideo",
      "insertParagraph",
      "likeDislike",
      "menuInteractive",
      "multipleChoice",
      "numericalScale",
      "openAnswerCellPhone",
      "openAnswerCPF",
      "openAnswerDate",
      "openAnswerHour",
      "openAnswerEmail",
      "openAnswerFreeText",
      "openAnswerOnlyNumbers",
      "openAnswerPhone",
      "starThreeScale",
      "starFiveScale",
    ];
    if (arrayTypes.indexOf(question.type) >= 0) {
      return (
        <div key={position} ref={ref} id={`question-${position + 1}`}>
          <AnswerType
            addQuestion={addQuestion}
            handleAddOption={handleAddOption}
            handleCancel={handleCancel}
            handleColor={handleColor}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleEdit={handleEdit}
            handleKeyDown={handleKeyDown}
            handleMessage={handleMessage}
            handleNextOptions={handleNextOptions}
            handleNextMultipleOptions={handleNextMultipleOptions}
            handleOptionValue={handleOptionValue}
            handleRemoveOption={handleRemoveOption}
            handleSave={handleSave}
            handleSaveAndNew={handleSaveAndNew}
            key={position}
            moveDownQuestion={moveDownQuestion}
            moveUpQuestion={moveUpQuestion}
            moveDownOption={moveDownOption}
            moveUpOption={moveUpOption}
            questionNumber={position}
            questionValues={chatbotTemp}
            removeQuestion={removeQuestion}
            setQuestionValues={setChatbotTemp}
            lastQuestion={lastQuestion}
            projectId={props.projectId}
            type={question.type}
          />
        </div>
      );
    } else {
      return (
        <div key={position} ref={ref} id={`question-${position + 1}`}>
          <DefaultQuestion
            addQuestion={addQuestion}
            handleCancel={handleCancel}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleEdit={handleEdit}
            handleKeyDown={handleKeyDown}
            handleMessage={handleMessage}
            handleNextOptions={handleNextOptions}
            handleSave={handleSave}
            handleSaveAndNew={handleSaveAndNew}
            key={position}
            moveDownQuestion={moveDownQuestion}
            moveUpQuestion={moveUpQuestion}
            questionNumber={position}
            questionValues={chatbotTemp}
            removeQuestion={removeQuestion}
            setQuestionValues={setChatbotTemp}
            lastQuestion={lastQuestion}
          />
        </div>
      );
    }
  };

  const handleRemoveOption = (questionNumber, optionNumber) => {
    let arrayTemp = [...chatbotTemp.questions];
    let arrayRemoved = [...arrayTemp[questionNumber].options];
    arrayRemoved.splice(optionNumber, 1);
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: arrayRemoved,
      error: false,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const handleSave = async (questionNumber) => {
    let errorExists = validQuestions();
    if (!errorExists) {
      handleScroll(questionNumber);
      let arrayTemp = [...chatbotTemp.questions];
      arrayTemp[questionNumber] = {
        ...arrayTemp[questionNumber],
        edit: 0,
      };
      setChatbot({
        ...chatbot,
        questions: arrayTemp,
      });
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });

      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        props.projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        props.projectId,
        projectJson,
        props.usedNumbers
      );
    }
  };

  const handleSaveAndNew = async (questionNumber) => {
    let errorExists = validQuestions();
    if (!errorExists) {
      handleScroll(questionNumber);
      let arrayTemp = [...chatbotTemp.questions];
      arrayTemp[questionNumber] = {
        ...arrayTemp[questionNumber],
        edit: 0,
      };

      let newQuestionNumber = 0;
      for (let i = 0; i < chatbotTemp.questions.length; i++) {
        if (chatbotTemp.questions[i].number > newQuestionNumber) {
          newQuestionNumber = chatbotTemp.questions[i].number;
        }
      }
      newQuestionNumber++;

      if (props.usedNumbers.indexOf(newQuestionNumber) > -1) {
        newQuestionNumber = Math.max(...props.usedNumbers) + 1;
        props.setUsedNumbers([...props.usedNumbers, newQuestionNumber]);
      } else {
        props.setUsedNumbers([...props.usedNumbers, newQuestionNumber]);
      }

      setChatbot({
        ...chatbot,
        questions: arrayTemp,
      });
      setChatbotTemp({
        ...chatbotTemp,
        questions: [
          ...arrayTemp.slice(0, questionNumber + 1),
          {
            message: "",
            number: newQuestionNumber,
            options: [],
            type: "",
            edit: 1,
            error: false,
          },
          ...arrayTemp.slice(questionNumber + 1, arrayTemp.length),
        ],
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        props.projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        props.projectId,
        projectJson,
        props.usedNumbers
      );
    }
  };

  const handleShare = async () => {
    setChatbot(chatbotTemp);
    await createDictionary(chatbotTemp, props.projectId);
    await JsonDictionaryService.jsonDictionarySaveFirebase(
      props.projectId,
      JSON.stringify(chatbotTemp)
    );
    history.push(`/share/${props.projectId}`);
  };

  const handleTestChatbot = async () => {
    setChatbot(chatbotTemp);
    await createDictionary(chatbotTemp, props.projectId);
    await JsonDictionaryService.jsonDictionarySaveFirebase(
      props.projectId,
      JSON.stringify(chatbotTemp)
    );
    window.open(
      `${process.env.REACT_APP_BASE_URL}testing/${props.projectLink}`
    );
  };

  const moveDownOption = async (questionNumber, questionOptionNumber) => {
    let arrayTemp = [...chatbotTemp.questions];
    let positionTemp = arrayTemp[questionNumber];
    if (questionOptionNumber < positionTemp.options.length - 1) {
      let firstValue = positionTemp.options[questionOptionNumber];
      let secondValue = positionTemp.options[questionOptionNumber + 1];
      let arrayOptionsTemp = positionTemp.options;
      arrayOptionsTemp[questionOptionNumber] = secondValue;
      arrayOptionsTemp[questionOptionNumber + 1] = firstValue;
      positionTemp.options = arrayOptionsTemp;

      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        props.projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        props.projectId,
        projectJson
      );
    }
  };

  const moveDownQuestion = async (questionNumber) => {
    if (questionNumber < chatbot.questions.length - 1) {
      let arrayTemp = [...chatbotTemp.questions];
      let positionTemp = arrayTemp[questionNumber];
      arrayTemp[questionNumber] = arrayTemp[questionNumber + 1];
      arrayTemp[questionNumber + 1] = positionTemp;
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        props.projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        props.projectId,
        projectJson
      );
    }
  };

  const moveUpQuestion = async (questionNumber) => {
    if (questionNumber > 0) {
      let arrayTemp = [...chatbotTemp.questions];
      let positionTemp = arrayTemp[questionNumber - 1];
      arrayTemp[questionNumber - 1] = arrayTemp[questionNumber];
      arrayTemp[questionNumber] = positionTemp;
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        props.projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        props.projectId,
        projectJson
      );
    }
  };

  const moveUpOption = async (questionNumber, questionOptionNumber) => {
    let arrayTemp = [...chatbotTemp.questions];
    let positionTemp = arrayTemp[questionNumber];
    let max = 0;

    switch (arrayTemp[questionNumber].type) {
      case "likeDislike":
        max = 2;
        break;
      case "emojiScale":
        max = 5;
        break;
      case "starThreeScale":
        max = 3;
        break;
      case "starFiveScale":
        max = 5;
        break;
      default:
        break;
    }

    if (questionOptionNumber > max) {
      let firstValue = positionTemp.options[questionOptionNumber];
      let secondValue = positionTemp.options[questionOptionNumber - 1];
      let arrayOptionsTemp = positionTemp.options;
      arrayOptionsTemp[questionOptionNumber] = secondValue;
      arrayOptionsTemp[questionOptionNumber - 1] = firstValue;
      positionTemp.options = arrayOptionsTemp;

      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        props.projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        props.projectId,
        projectJson
      );
    }
  };

  const removeQuestion = async (questionNumber) => {
    const questionsValidated = await handleWrongReferenceNextQuestion(
      questionNumber
    );
    let arrayTemp = [];
    for (let i = 0; i < questionsValidated.length; i++) {
      if (i !== questionNumber) {
        arrayTemp.push(questionsValidated[i]);
      }
    }
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
    let projectJson = JSON.stringify({
      ...chatbotTemp,
      questions: arrayTemp,
    });
    await createDictionary(
      {
        ...chatbotTemp,
        questions: arrayTemp,
      },
      props.projectId
    );
    await JsonDictionaryService.jsonDictionarySaveFirebase(
      props.projectId,
      projectJson
    );
  };

  const handleWrongReferenceNextQuestion = async (questionPosition) => {
    let questionNumber = chatbotTemp.questions[questionPosition].number;
    let questionsTemp = [...chatbotTemp.questions];

    for (let i = 0; i < questionsTemp.length; i++) {
      if (Array.isArray(questionsTemp[i].options)) {
        for (let j = 0; j < questionsTemp[i].options.length; j++) {
          let next = questionsTemp[i].options[j].next;
          if (next === questionNumber) {
            questionsTemp[i].options[j].next = 0;
          }
        }
      }
    }

    return questionsTemp;
  };

  const setInView = (inView, entry) => {
    if (inView && !isAutoScrolling) {
      const idQuestion = entry.target.getAttribute("id");
      const numberQuestion = Number(idQuestion.split("-")[1]);
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        setInitialIdQuestion(numberQuestion - 3);
      } else if (currentScrollTop < lastScrollTop) {
        setInitialIdQuestion(numberQuestion);
      }
      setLastScrollTop(currentScrollTop);
    }
  };

  useEffect(() => {
    if (chatbotTemp.length > 0) {
      window.scroll(0, document.getElementById("body").clientHeight + 1000);
    }
  }, [chatbotTemp]);

  useEffect(() => {
    if (chatbotTemp.length > 0) {
      setForceUpdade(Math.round(Math.random() * (100000 - 0) + 0));
    }
  }, [chatbotTemp]);

  useEffect(() => {
    if (!logic) {
      const idQuestion = `question-${initialIdQuestion}`;
      const question = document.getElementById(idQuestion);

      if (question) {
        const scrollMargin = 150;
        const scrollToY =
          question.getBoundingClientRect().top +
          window.pageYOffset -
          scrollMargin;

        setIsAutoScrolling(true);
        window.scrollTo({ top: scrollToY, behavior: "auto" });

        autoScrollTimeout.current = setTimeout(() => {
          setIsAutoScrolling(false);
        }, 500);
      }
    }

    return () => clearTimeout(autoScrollTimeout.current);
    // eslint-disable-next-line
  }, [logic]);

  return (
    <section id="body" className="chatbotBody">
      <div style={{ display: "none" }}>{forceUpdate}</div>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <ChatbotHeader
        generatePdf={props.generatePdf}
        handleTestChatbot={handleTestChatbot}
        projectId={props.projectId}
        questionValues={chatbotTemp}
        page="botmaker"
        setQuestionValues={setChatbotTemp}
        setForceUpdade={setForceUpdade}
        logic={logic}
        setLogic={setLogic}
      />
      <NavigationHeader
        handleShare={handleShare}
        page="botmaker"
        projectId={props.projectId}
        questionValues={chatbotTemp}
        setQuestionValues={setChatbotTemp}
        validQuestions={validQuestions}
      />
      <div className="container_message">
        <div className="content">
          <h1 className="project-name">
            Projeto: <strong>{props.project.name}</strong>
          </h1>
          {!logic ? (
            <div ref={ref}>
              <DisclaimerInitial
                addQuestion={addQuestion}
                questionValues={chatbotTemp}
                setQuestionValues={setChatbotTemp}
                projectId={props.projectId}
                validQuestions={validQuestions}
                lastQuestion={lastQuestion}
              />
              {chatbotTemp.questions.map((question, index) => {
                return (
                  <InView onChange={setInView} threshold={0.8} key={index}>
                    {({ ref }) => {
                      return handleQuestion(question, index, ref);
                    }}
                  </InView>
                );
              })}
            </div>
          ) : (
            <>
              <span className="titleLogic">Área de exibição da Lógica</span>
              <AnswersLogic
                questionValues={chatbotTemp}
                addQuestion={addQuestion}
                setQuestionValues={setChatbotTemp}
                projectId={props.projectId}
                validQuestions={validQuestions}
                lastQuestion={lastQuestion}
                handleDuplicateQuestion={handleDuplicateQuestion}
                handleEdit={handleEdit}
                moveUpQuestion={moveUpQuestion}
                moveDownQuestion={moveDownQuestion}
                removeQuestion={removeQuestion}
                initialIdQuestion={initialIdQuestion}
                setInitialIdQuestion={setInitialIdQuestion}
              />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ChatbotBody;
