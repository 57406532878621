import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../../Context/Context";
import { Tooltip } from "reactstrap";
import ClosedAnswer from "../CloseAnswer";
import ConfigurationQuestionButtons from "../../ConfigurationQuestionButtons";
import CondicionalsColor from "../CondicionalsColor";
import EditQuestionMenu from "../../EditQuestionMenu";
import EmojiScale from "../EmojiScale";
import InsertImage from "../InsertImage";
import InsertVideo from "../InsertVideo";
import InsertParagraph from "../InsertTextBox";
import LikeDislike from "../LikeDislike";
import ModalAnswers from "../../Modals/ModalAnswers";
import ModalPresets from "../../Modals/ModalPresets";
import MenuInteractive from "../MenuInteractive";
import MultipleChoice from "../MultipleChoice";
import NumericalScale from "../NumericalScale";
import OpenAnswer from "../OpenAnswer";
import Polygon from "../../../Assets/img/polygon_down.svg";
import RenderQuestions from "../RenderQuestions";
import StartScale from "../StarScale";
import "./style.scss";
import "../style.scss";

const AnswerType = (props) => {
  const { questions } = useContext(Context);
  const [modalAnswersOpen, setModalAnswersOpen] = useState(false);
  const [modalPresetsOpen, setModalPresetsOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");

  const handleClickInput = (element) => {
    document.getElementById(element).click();
  };

  const handleInputToSave = () => {
    props.handleSave(props.questionNumber);
  };

  const handleInputToSaveNew = () => {
    props.handleSaveAndNew(props.questionNumber);
  };

  const condicionalsArea = (type) => {
    const types = [
      "insertImage",
      "insertParagraph",
      "insertVideo",
      "multipleChoice",
      "openAnswerCellPhone",
      "openAnswerCPF",
      "openAnswerDate",
      "openAnswerEmail",
      "openAnswerFreeText",
      "openAnswerHour",
      "openAnswerOnlyNumbers",
      "openAnswerPhone",
    ];

    if (types.includes(type)) return;

    return (
      <div className="conditionsArea">
        <hr />
        <span>Lógica (Condicionais de resposta)</span>
        {props.questionValues.questions[props.questionNumber].options.map(
          (option, index) => {
            return (
              <CondicionalsColor
                key={index}
                index={index}
                option={option}
                questionNumber={props.questionNumber}
                questionValues={props.questionValues}
                handleNextOptions={props.handleNextOptions}
              />
            );
          }
        )}
      </div>
    );
  };

  const handleQuestionType = (el, what) => {
    let { type } = el;

    if (type.includes("openAnswer")) {
      type = "openAnswer";
    }

    if (type.includes("star")) {
      type = "starScale";
    }

    const typeSelected = questions.filter(
      (element) => element.type === type
    )[0];
    if (what === "question") {
      return `Pergunta ${props.questionNumber + 1} - ${typeSelected.question}`;
    } else {
      return typeSelected.name;
    }
  };

  const handleType = (type) => {
    switch (type) {
      case "emojiScale":
        return (
          <EmojiScale
            handleClickInput={handleClickInput}
            setTooltipOpen={setTooltipOpen}
            questionValues={props.questionValues}
            handleColor={props.handleColor}
            handleOptionValue={props.handleOptionValue}
            handleRemoveOption={props.handleRemoveOption}
            handleKeyDown={props.handleKeyDown}
            moveDownOption={props.moveDownOption}
            moveUpOption={props.moveUpOption}
            questionNumber={props.questionNumber}
            handleAddOption={props.handleAddOption}
          />
        );
      case "closeAnswer":
        return (
          <ClosedAnswer
            handleClickInput={handleClickInput}
            setTooltipOpen={setTooltipOpen}
            questionValues={props.questionValues}
            handleColor={props.handleColor}
            handleOptionValue={props.handleOptionValue}
            handleRemoveOption={props.handleRemoveOption}
            handleKeyDown={props.handleKeyDown}
            moveDownOption={props.moveDownOption}
            moveUpOption={props.moveUpOption}
            questionNumber={props.questionNumber}
            handleAddOption={props.handleAddOption}
          />
        );
      case "insertImage":
        return (
          <InsertImage
            questionValues={props.questionValues}
            questionNumber={props.questionNumber}
            setQuestionValues={props.setQuestionValues}
          />
        );
      case "insertVideo":
        return (
          <InsertVideo
            questionValues={props.questionValues}
            questionNumber={props.questionNumber}
            setQuestionValues={props.setQuestionValues}
          />
        );
      case "insertParagraph":
        return (
          <InsertParagraph
            questionValues={props.questionValues}
            questionNumber={props.questionNumber}
            setQuestionValues={props.setQuestionValues}
          />
        );
      case "likeDislike":
        return (
          <LikeDislike
            handleClickInput={handleClickInput}
            setTooltipOpen={setTooltipOpen}
            questionValues={props.questionValues}
            handleColor={props.handleColor}
            handleOptionValue={props.handleOptionValue}
            handleRemoveOption={props.handleRemoveOption}
            handleKeyDown={props.handleKeyDown}
            moveDownOption={props.moveDownOption}
            moveUpOption={props.moveUpOption}
            questionNumber={props.questionNumber}
            handleAddOption={props.handleAddOption}
          />
        );
      case "menuInteractive":
        return (
          <MenuInteractive
            handleClickInput={handleClickInput}
            setTooltipOpen={setTooltipOpen}
            questionValues={props.questionValues}
            handleColor={props.handleColor}
            handleOptionValue={props.handleOptionValue}
            handleRemoveOption={props.handleRemoveOption}
            handleKeyDown={props.handleKeyDown}
            moveDownOption={props.moveDownOption}
            moveUpOption={props.moveUpOption}
            questionNumber={props.questionNumber}
            handleAddOption={props.handleAddOption}
            handleNextOptions={props.handleNextOptions}
            handleNextMultipleOptions={props.handleNextMultipleOptions}
            setQuestionValues={props.setQuestionValues}
          />
        );
      case "multipleChoice":
        return (
          <MultipleChoice
            handleClickInput={handleClickInput}
            setTooltipOpen={setTooltipOpen}
            questionValues={props.questionValues}
            handleColor={props.handleColor}
            handleOptionValue={props.handleOptionValue}
            handleRemoveOption={props.handleRemoveOption}
            handleKeyDown={props.handleKeyDown}
            moveDownOption={props.moveDownOption}
            moveUpOption={props.moveUpOption}
            questionNumber={props.questionNumber}
            handleAddOption={props.handleAddOption}
            handleNextOptions={props.handleNextOptions}
            handleNextMultipleOptions={props.handleNextMultipleOptions}
            setQuestionValues={props.setQuestionValues}
          />
        );
      case "numericalScale":
        return (
          <NumericalScale
            handleClickInput={handleClickInput}
            setTooltipOpen={setTooltipOpen}
            questionValues={props.questionValues}
            questionNumber={props.questionNumber}
            setQuestionValues={props.setQuestionValues}
          />
        );
      case "openAnswerCellPhone":
      case "openAnswerCPF":
      case "openAnswerDate":
      case "openAnswerEmail":
      case "openAnswerFreeText":
      case "openAnswerHour":
      case "openAnswerOnlyNumbers":
      case "openAnswerPhone":
        return (
          <OpenAnswer
            handleClickInput={handleClickInput}
            setTooltipOpen={setTooltipOpen}
            questionValues={props.questionValues}
            handleColor={props.handleColor}
            handleOptionValue={props.handleOptionValue}
            handleRemoveOption={props.handleRemoveOption}
            handleKeyDown={props.handleKeyDown}
            moveDownOption={props.moveDownOption}
            moveUpOption={props.moveUpOption}
            questionNumber={props.questionNumber}
            handleAddOption={props.handleAddOption}
            handleNextOptions={props.handleNextOptions}
            handleNextMultipleOptions={props.handleNextMultipleOptions}
            setQuestionValues={props.setQuestionValues}
          />
        );
      case "starThreeScale":
      case "starFiveScale":
        return (
          <StartScale
            handleClickInput={handleClickInput}
            setTooltipOpen={setTooltipOpen}
            questionValues={props.questionValues}
            handleColor={props.handleColor}
            handleOptionValue={props.handleOptionValue}
            handleRemoveOption={props.handleRemoveOption}
            handleKeyDown={props.handleKeyDown}
            moveDownOption={props.moveDownOption}
            moveUpOption={props.moveUpOption}
            questionNumber={props.questionNumber}
            handleAddOption={props.handleAddOption}
            handleNextOptions={props.handleNextOptions}
            handleNextMultipleOptions={props.handleNextMultipleOptions}
            setQuestionValues={props.setQuestionValues}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    const validError = () => {
      if (props.questionValues.questions[props.questionNumber].message === "") {
        setTooltipMessage("Não esqueça de escrever sua pergunta");
        setTooltipOpen(true);
      } else if (
        props.questionValues.questions[props.questionNumber].options === ""
      ) {
        if (props.type === "insertImage") {
          setTooltipMessage("Coloque o link da imagem");
        }
        if (props.type === "insertVideo") {
          setTooltipMessage("Coloque o link do vídeo");
        }
        setTooltipOpen(true);
      }
    };

    if (props.questionValues.questions[props.questionNumber].error) {
      validError();
    } else {
      setTooltipOpen(false);
    }
    // eslint-disable-next-line
  }, [props.questionValues.questions[props.questionNumber]]);

  useEffect(() => {
    const validError = () => {
      if (props.questionValues.questions[props.questionNumber].message === "") {
        setTooltipMessage("Não esqueça de escrever sua pergunta");
        setTooltipOpen(true);
      } else if (
        props.questionValues.questions[props.questionNumber].type === ""
      ) {
        setTooltipMessage("Escolha um tipo de resposta");
        setTooltipOpen(true);
      } else {
        for (
          let i = 0;
          i <
          props.questionValues.questions[props.questionNumber].options.length;
          i++
        ) {
          if (
            props.questionValues.questions[props.questionNumber].options[i]
              .value === ""
          ) {
            setTooltipMessage("Há opções de resposta em branco");
            setTooltipOpen(true);
          }
        }
      }
    };

    if (props.questionValues.questions[props.questionNumber].error) {
      validError();
    } else {
      setTooltipOpen(false);
    }
    // eslint-disable-next-line
  }, [props.questionValues.questions[props.questionNumber]]);

  if (!props.questionValues.questions[props.questionNumber].edit) {
    return (
      <div className="compCloseAnswer boxNoEdit">
        <img
          alt="robot"
          className="robotIcon"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.robot_image_name}`}
          style={{
            visibility:
              props.lastQuestion === props.questionNumber + 1
                ? "visible"
                : "hidden",
          }}
        />
        <div className="questionArea">
          <div className="title">
            {handleQuestionType(
              props.questionValues.questions[props.questionNumber],
              "question"
            )}
          </div>
          <div className="inputTitle">
            <p style={{ whiteSpace: "pre-line" }}>
              {props.questionValues.questions[props.questionNumber].message}
            </p>
            {!props.type.includes("openAnswer") &&
              props.type !== "insertParagraph" && (
                <div className="showOptionsArea">
                  <RenderQuestions
                    type={props.type}
                    questionValues={props.questionValues}
                    questionNumber={props.questionNumber}
                  />
                </div>
              )}
          </div>
          <ConfigurationQuestionButtons
            addQuestion={props.addQuestion}
            handleDuplicateQuestion={props.handleDuplicateQuestion}
            handleEdit={props.handleEdit}
            moveUpQuestion={props.moveUpQuestion}
            moveDownQuestion={props.moveDownQuestion}
            questionNumber={props.questionNumber}
            removeQuestion={props.removeQuestion}
            showNew={
              props.questionValues.questions.length - 1 === props.questionNumber
                ? true
                : false
            }
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="compMultipleChoice boxEdit">
        <img
          alt="robot"
          className="robotIcon"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.robot_image_name}`}
          style={{
            visibility:
              props.lastQuestion === props.questionNumber + 1
                ? "visible"
                : "hidden",
          }}
        />
        <ModalAnswers
          questionNumber={props.questionNumber}
          questionValues={props.questionValues}
          setQuestionValues={props.setQuestionValues}
          modal={modalAnswersOpen}
          setModal={setModalAnswersOpen}
        />
        <ModalPresets
          questionNumber={props.questionNumber}
          questionValues={props.questionValues}
          setQuestionValues={props.setQuestionValues}
          modal={modalPresetsOpen}
          setModal={setModalPresetsOpen}
        />
        <div className="questionArea">
          <div className="title">
            {handleQuestionType(
              props.questionValues.questions[props.questionNumber],
              "question"
            )}
          </div>
          <div
            id={"Pergunta" + props.questionNumber}
            className={
              !tooltipOpen ? "inputTitle" : "inputTitle inputTitleError"
            }
          >
            <Tooltip
              innerClassName="redBackground"
              className="redBackground"
              placement="top"
              popperClassName="tooltipZero"
              isOpen={tooltipOpen}
              target={"Pergunta" + props.questionNumber}
            >
              {tooltipMessage}
            </Tooltip>
            <button
              className="buttonPresets"
              onClick={() => setModalPresetsOpen(true)}
            >
              Presets
            </button>
            <textarea
              rows="3"
              className="buttonPresetInputPadding form-control"
              value={
                props.questionValues.questions[props.questionNumber].message
              }
              placeholder="Digite aqui a sua pergunta"
              onChange={(event) => {
                setTooltipOpen(false);
                props.handleMessage(props.questionNumber, event.target.value);
                props.handleKeyDown(event);
              }}
            />
            <button
              className="buttonSelectAnswer"
              onClick={() => setModalAnswersOpen(true)}
            >
              {handleQuestionType(
                props.questionValues.questions[props.questionNumber],
                "name"
              )}
              <img alt="poligono" className="polygon" src={Polygon} />
            </button>
            {handleType(props.type)}
            {condicionalsArea(props.type)}
          </div>
          <EditQuestionMenu
            handleCancel={props.handleCancel}
            handleSave={handleInputToSave}
            handleSaveAndNew={handleInputToSaveNew}
            questionNumber={props.questionNumber}
            showSavePlusNew={
              props.questionValues.questions.length - 1 === props.questionNumber
                ? true
                : false
            }
          />
        </div>
      </div>
    );
  }
};

export default AnswerType;
