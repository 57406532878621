import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { SiGooglesheets } from "react-icons/si";
import { FaLock } from "react-icons/fa";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Context } from "../../Context/Context";
import { useParams, Link } from "react-router-dom";
import ButtonOnOff from "../ButtonOnOff";
import Loading from "../Loading";
import ScheduleService from "../../Services/ScheduleService";
import SheetsIntegration from "../SheetsIntegration";

const ReportData = ({ getDatabaseToXlsx }) => {
  const { userInformation, sheetLink, setSheetLink } = useContext(Context);
  let { projectId } = useParams();
  const [loading, setLoading] = useState(true);
  const [openDetails, setOpenDetails] = useState(false);
  const [newArr, setNewArr] = useState(null);
  const [isGoogleSheetsLocked, setIsGoogleSheetsLocked] = useState(false);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    const getDataToTable = async () => {
      let newArray = await handleExportDatabase();

      setNewArr(newArray);
      await getIntegrationIsOn();
      setLoading(false);
    };

    const setIfGoogleSheetsIsLocked = () => {
      const validSubscriptions = ["idss manager", "enterprise"];
      const userSubscription = userInformation.subscription.toLowerCase();

      if (validSubscriptions.includes(userSubscription)) {
        setIsGoogleSheetsLocked(false);
      } else {
        setIsGoogleSheetsLocked(true);
      }
    };

    if (userInformation !== null) {
      getDataToTable();
      setIfGoogleSheetsIsLocked();
    }
    //eslint-disable-next-line
  }, [userInformation]);

  const getIntegrationIsOn = async () => {
    let response = await ScheduleService.getSchedule({
      user: userInformation.email,
      campaign: projectId,
    });
    if (
      response.data !== "Integration not exists" &&
      response.data !== "Error happened"
    ) {
      setSheetLink(true);
    }
  };

  const handleExportDatabase = async () => {
    if (userInformation.subscription !== "Idss") {
      return await getDatabaseToXlsx();
    } else {
      return null;
    }
  };

  const handleOpenDetails = async () => {
    if (sheetLink) {
      await ScheduleService.deleteSchedule({
        user: userInformation.email,
        campaign: projectId,
      });
      setSheetLink(false);
    } else {
      setOpenDetails(!openDetails);
    }
  };

  useEffect(() => {
    if (warning) {
      setWarning(false);
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="compReports">
        {openDetails ? (
          <SheetsIntegration handleOpenDetails={handleOpenDetails} />
        ) : (
          <div className="container-data">
            {newArr === null ? null : (
              <>
                {warning && (
                  <div className="wrapper">
                    <div className="warning">
                      <div className="warning-content">
                        <IoInformationCircleOutline
                          className="icon"
                          size={24}
                        />
                        <p>
                          Esta funcionalidade não faz parte do seu plano. Faça{" "}
                          <Link to="/plans" className="link">
                            upgrade
                          </Link>{" "}
                          para utilizar
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    isGoogleSheetsLocked ? "integration locked" : "integration"
                  }
                  onClick={() => setWarning(true)}
                >
                  <div className="integration-elements">
                    <SiGooglesheets
                      className="icon"
                      color="green"
                      size="50px"
                    />
                    <div className="integration-texts">
                      <h4>Google Sheets</h4>
                      <p>Sincronize suas respostas com o Planilhas Google.</p>
                    </div>
                    <div className="integration-switch">
                      {isGoogleSheetsLocked ? (
                        <FaLock className="icon" size={24} />
                      ) : (
                        <ButtonOnOff
                          on={sheetLink}
                          handleValue={handleOpenDetails}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className={newArr === null ? `content-without-plan` : `content`}
            >
              {newArr === null ? (
                <p className="upgrade-plan">
                  Esta funcionalidade não faz parte do seu plano. Faça{" "}
                  <Link to="/plans" className="link">
                    upgrade
                  </Link>{" "}
                  para utilizar
                </p>
              ) : (
                <div className="table-area">
                  <table>{newArr}</table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default ReportData;
