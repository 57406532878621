import React, { useEffect, useRef, useState, useContext } from "react";
import { Context } from "../../Context/Context";
import { InView } from "react-intersection-observer";
import { FaLongArrowAltDown } from "react-icons/fa";
import Dislike from "../../Assets/img/dislike.svg";
import Emoji1 from "../../Assets/img/emoji-1.svg";
import Emoji2 from "../../Assets/img/emoji-2.svg";
import Emoji3 from "../../Assets/img/emoji-3.svg";
import Emoji4 from "../../Assets/img/emoji-4.svg";
import Emoji5 from "../../Assets/img/emoji-5.svg";
import Like from "../../Assets/img/like.svg";
import Star from "../../Assets/img/star.svg";
import "./styles.scss";

const Logic = (props) => {
  const divRef = useRef(null);
  const { questions } = useContext(Context);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const autoScrollTimeout = useRef(null);

  const handleNext = (option) => {
    let next = "";

    if (option.next === 0 || option.type === "multipleChoice") {
      next = "Próxima";
    } else {
      const array = props.questionValues.questions.filter(
        ({ number }) => number === option.next
      );
      if (array[0] !== undefined) {
        const { message } = array[0];
        const questionNumber =
          props.questionValues.questions.indexOf(array[0]) + 1;
        next = ` Q-${questionNumber}: ${message}`;
      } else {
        next = "Despedida";
      }
    }
    return <div className="next">{next}</div>;
  };

  const handleType = (option, questionNumber) => {
    switch (option.type) {
      case "insertImage":
        return (
          <div className="imageContainer">
            <img
              alt="Mídia enviada pelo usuário"
              src={props.questionValues.questions[questionNumber].options}
            />
          </div>
        );
      case "insertVideo":
        return (
          <iframe
            title="Mídia enviada pelo usuário"
            width="560"
            height="315"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            src={props.questionValues.questions[questionNumber].options.replace(
              "watch?v=",
              "v/"
            )}
          />
        );
      case "starThreeScale":
      case "starFiveScale":
        return option.options.map((option, index) => {
          if (option.value.match(/^star-[0-9]$/)) {
            let starQuantity = [];
            const starNumber = option.value.split("-")[1];
            for (let i = 0; i < starNumber; i++) {
              starQuantity.push(
                <img
                  className="starIconEditOff"
                  key={i}
                  alt="Star"
                  src={Star}
                />
              );
            }
            return (
              <div
                key={index}
                style={{
                  backgroundColor: option.color,
                }}
                className="showOptionsButton"
              >
                {starQuantity}
              </div>
            );
          } else {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: option.color,
                }}
                className="showOptionsButton"
              >
                {option.value}
              </div>
            );
          }
        });
      case "likeDislike":
        return option.options.map((option) => {
          const value = option.value.toLowerCase();
          const options = ["like", "dislike"];
          if (options.includes(value) === false)
            return (
              <div
                style={{
                  backgroundColor: option.color,
                }}
                className="showOptionsButton"
              >
                {option.value}
              </div>
            );

          const isLike = option.value === "Like";
          return (
            <img
              className="likeDislikeIconEditOff"
              style={{ height: 43, width: 43 }}
              alt={isLike ? "like" : "dislike"}
              src={isLike ? Like : Dislike}
            />
          );
        });
      case "emojiScale":
        return option.options.map((option, index) => {
          return (
            <div
              key={index}
              className="showOptionsButton"
              style={{
                backgroundColor: option.color,
              }}
            >
              {option.value === "emoji-1" && (
                <img
                  style={{ height: 30, width: 30 }}
                  alt="Emoji 1"
                  src={Emoji1}
                />
              )}
              {option.value === "emoji-2" && (
                <img
                  style={{ height: 30, width: 30 }}
                  alt="Emoji 2"
                  src={Emoji2}
                />
              )}
              {option.value === "emoji-3" && (
                <img
                  style={{ height: 30, width: 30 }}
                  alt="Emoji 3"
                  src={Emoji3}
                />
              )}
              {option.value === "emoji-4" && (
                <img
                  style={{ height: 30, width: 30 }}
                  alt="Emoji 4"
                  src={Emoji4}
                />
              )}
              {option.value === "emoji-5" && (
                <img
                  style={{ height: 30, width: 30 }}
                  alt="Emoji 5"
                  src={Emoji5}
                />
              )}
              {!option.value.includes("emoji-") && option.value}
            </div>
          );
        });
      case "closeAnswer":
      case "menuInteractive":
      case "numericalScale":
        return option.options.map((option, index) => {
          return (
            <div
              key={index}
              className="showOptionsButton"
              style={{
                backgroundColor: option.color,
              }}
            >
              {option.value}
            </div>
          );
        });
      default:
        return;
    }
  };

  const handleQuestionType = (type) => {
    if (type.includes("openAnswer")) {
      return { question: "Pergunta Aberta" };
    }

    if (type.includes("star")) {
      return { question: "Escala Estrela" };
    }

    return questions.filter((question) => question.type === type)[0];
  };

  const handleOpenAnswer = () => {
    return (
      <div className="sectionOption">
        <div className="option">
          <div className="showOptionsButton">Prefiro não informar</div>
        </div>
        <FaLongArrowAltDown />
        <div className="show-next">Próxima</div>
      </div>
    );
  };

  const handleMultipleChoice = (question) => {
    return (
      <>
        <div className="sectionOption">
          <div className="option">
            {question.options.map((option, index) => (
              <div
                key={index}
                className="showOptionsButton"
                style={{
                  backgroundColor: option.color,
                }}
              >
                {option.value}
              </div>
            ))}
            {question.otherOption?.value && (
              <div
                className="showOptionsButton"
                style={{
                  backgroundColor: question.color,
                }}
              >
                Outra (escreva abaixo)
              </div>
            )}
          </div>
          <FaLongArrowAltDown />
          <div className="show-next">{handleNext(question)}</div>
        </div>
        {question.noneQuestion?.value && (
          <div className="sectionOption">
            <div className="option">
              <div
                className="showOptionsButton"
                style={{
                  backgroundColor: question.color,
                }}
              >
                Nenhuma das opções anteriores
              </div>
            </div>
            <FaLongArrowAltDown />
            <div className="show-next">{handleNext(question.noneQuestion)}</div>
          </div>
        )}
      </>
    );
  };

  const setInView = (inView, entry) => {
    if (inView && !isAutoScrolling) {
      const idQuestion = entry.target.getAttribute("id");
      const numberQuestion = Number(idQuestion.split("-")[1]);

      props.setInitialIdQuestion(numberQuestion);
    }
  };

  useEffect(() => {
    if (divRef.current) {
      const scrollMargin = 150;
      const scrollToY =
        divRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        scrollMargin;

      setIsAutoScrolling(true);
      window.scrollTo({ top: scrollToY, behavior: "smooth" });

      autoScrollTimeout.current = setTimeout(() => {
        setIsAutoScrolling(false);
      }, 1000);
    }

    return () => clearTimeout(autoScrollTimeout.current);
  }, []);

  return props.questionValues.questions.map((question, index) => {
    let groupByNext = [];
    if (typeof question.options === "object") {
      question.options.forEach((option) => {
        const existingGroup = groupByNext.find((x) => x.next === option.next);
        if (existingGroup) {
          existingGroup.options.push(option);
        } else {
          groupByNext.push({
            next: option.next,
            options: [option],
            type: question.type,
          });
        }
      });
    } else {
      groupByNext.push({
        next: 0,
        options: question.options,
        type: question.type,
      });
    }

    return (
      <div
        key={index + 1}
        ref={index + 1 === props.initialIdQuestion ? divRef : null}
      >
        <InView onChange={setInView} threshold={0.9}>
          {({ ref }) => {
            return (
              <div
                className="compLogic boxEdit"
                ref={ref}
                id={`question-${index + 1}`}
              >
                <div className="questionArea">
                  <div className="title">
                    Pergunta {index + 1} -{" "}
                    {handleQuestionType(question.type)?.question}
                  </div>
                  <div className="inputTitle">
                    <p style={{ whiteSpace: "pre-line" }}>{question.message}</p>
                    <div className="showOptionsArea">
                      {question.type.includes("openAnswer") &&
                      question.preferNotAnswer ? (
                        handleOpenAnswer()
                      ) : question.type === "multipleChoice" ? (
                        handleMultipleChoice(question)
                      ) : (
                        <>
                          {groupByNext.map((option) => {
                            return (
                              <div className="sectionOption">
                                <div className="option">
                                  {handleType(option, index)}
                                </div>
                                <FaLongArrowAltDown />
                                <div className="show-next">
                                  {handleNext(option)}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </InView>
      </div>
    );
  });
};

export default Logic;
