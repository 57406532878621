import React from "react";
import "./style.scss";
import "../style.scss";

const InsertVideo = (props) => {
  const handleVideoUrl = (url) => {
    let arrayTemp = [...props.questionValues.questions];

    url = url.replace("watch?v=", "embed/");
    url = url.replace("https://youtu.be/", "https://www.youtube.com/embed/");

    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      options: url,
      error: false,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <div className="compInsertImage">
      <input
        className="form-control inputUrl"
        value={props.questionValues.questions[props.questionNumber].options}
        placeholder="Cole aqui link do vídeo do YouTube"
        onChange={(event) => {
          handleVideoUrl(event.target.value);
        }}
      />
      <p className="warning">
        Atenção: ao adicionar um link de imagem do Google Drive, lembre-se de
        configurar o compartilhamento como público.
      </p>
    </div>
  );
};

export default InsertVideo;
